import {mapMutations, mapActions, mapGetters} from "vuex";

//sections


export default {
  name: "manuals-category",
  components: {},
  props: {},
  data() {
    return {
      subcategory: [],
      crumbs: [
        {src: 'home', title: this.$t('home.title'), slug: ''},
        {src: 'manuals', title: this.$t('manuals.title'), slug: ''}
      ],
    }
  },
  created() {
    this.getSubCategory()

  },
  beforeUpdate() {
    return this.getSubCategory()
  },

  computed: {
    ...mapGetters({
      manuals: 'manuals/manuals',
    })
  },

  methods: {
    ...mapActions({
      getPage: 'manuals/GET_MANUAL_PAGE'
    }),

    getSubCategory() {
      let slug = this.$route.params.slug

      this.manuals.forEach(el => {
        if (el.slug === slug) {
          let duplicate = this.crumbs.find(crumb => crumb.title === el.title)
          if (!duplicate) {
            document.title = `${el.title} | karbosnab`
            this.crumbs.push({title: el.title, src: '', slug: ''})
          }
          this.subcategory = el.manualPages
        }
      })
    },
    ...mapMutations({})
  }
}
